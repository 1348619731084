<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <article class="tile is-child box">
        <div class="columns is-flex is-align-items-center">
          <div class="column">
            <p class="title">Two-Way Emails</p>
          </div>
          <div v-if="!hasError && showSavingStatus && !toastNotificationEnabled"
            class="column is-flex is-justify-content-flex-end is-align-items-center">
            <span v-if="saving"
              class="button is-loading is-white"
              style="height: 27px;" />
            <span v-else
              class="text has-text-success">
              <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px" />
            </span>
            <span class="is-size-6"
              :class="saving ? 'has-text-warning' : 'has-text-success'">{{ savingStatus }}</span>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half">
            <label class="label">Display Name</label>
            <div class="control">
              <input class="input"
                type="text"
                placeholder="Company Name"
                v-model="companySetting.eSetDisplayName">
              <span class="help is-danger"
                v-if="$v.companySetting.eSetDisplayName.$dirty && !$v.companySetting.eSetDisplayName.required">Name Required
              </span>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Display Email</label>
            <div class="control">
              <input class="input"
                type="text"
                placeholder="Company Email"
                v-model="companySetting.eSetEmail">
              <span class="help is-danger"
                v-if="$v.companySetting.eSetEmail.$dirty && !$v.companySetting.eSetEmail.required">Email Required
              </span>
              <span class="help is-danger"
                v-if="!$v.companySetting.eSetEmail.email">Please enter a valid email
              </span>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label mb-3">Company Signature Image</label>
          <div v-if="emailSignatureImage"
            class="is-flex is-align-items-center">
            <img :src="emailSignatureImage"
              class="emailSignature mr-5"
              alt="Company Email Signature Image">
            <button class="button has-text-danger"
              @click="removeImage"
              title="Delete">
              <i class="icon mdi mdi-delete mdi-24px" />
            </button>
          </div>

          <div v-else>
            <vue-dropzone
              ref="emailSignatureDropzone"
              :id="'emailSignatureDropzone'"
              :options="dropzoneOptions"
              @vdropzone-mounted="dropzoneMounted"
              @vdropzone-success="dropzoneSuccess"
              @vdropzone-error="dropzoneError" />
            <p class="text is-size-7 is-italic">Note: Maximum image file size is 100KB. One file only.</p>

          </div>
        </div>

        <confirm-modal v-if="emailSignatureImage"
          :active.sync="isConfirmModalActive"
          @ok="deleteImage(true)"
          @cancel="deleteImage(false)"
          :ok-text="'Yes'"
          :cancel-text="'No'">
          <p slot="text-title">Delete Company Signature Image</p>
          <p slot="text-content">
            Company Signature Image will be deleted. Continue?
          </p>
        </confirm-modal>
      </article>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import { ConfirmModal } from '@/components/BulmaModal'
import { required, email } from 'vuelidate/lib/validators'
import CommonApiService from '@/services/common'
import StoreMixin from './storeMixin'
import VueDropzone from '@/components/VueDropzone'

export default {
  name: 'CompanyEmailSetting',
  components: {
    ConfirmModal,
    VueDropzone
  },
  mixins: [StoreMixin],
  data() {
    return {
      dropzoneOptions: {
        url: `${process.env.VUE_APP_WEBAPI_URI}/companysettings/uploadCompanyEmailSignatureImage`,
        thumbnailWidth: 128,
        thumbnailHeight: 128,
        addRemoveLinks: true,
        maxFilesize: 0.1,
        maxFiles: 1,
        acceptedFiles: 'image/*',
        headers: {
          Authorization: ''
        },
        dictDefaultMessage: '<i class="icon mdi mdi-cloud-upload mdi-36px"></i><br>click or drop your image here',
        dictMaxFilesExceeded: 'You can only upload one file.',
        dictResponseError: 'Failed to upload the image.'
      },
      companySetting: {},
      isConfirmModalActive: false
    }
  },
  computed: {
    emailSignatureImage() {
      if (!this.companySetting || !this.companySetting.emailSignatureImage) { return '' }
      return 'data:image/jpg;base64,' + this.companySetting.emailSignatureImage
    }
  },
  watch: {
    companySetting: {
      handler: function(val) {
        this.$v.$touch()
        this.saveSnapshot({ entity: _cloneDeep(val) })
        this.autoUpdate(val)
      },
      deep: true
    }
  },
  async created() {
    this.$showSpinner()
    this.initializeAutoUpdate()
    this.companySetting = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getEntity' }))
    this.dropzoneOptions.headers.Authorization = `Bearer ${CommonApiService.getAuthToken()}`
    this.$hideSpinner()
  },
  methods: {
    async deleteImage(deleteConfirmed) {
      this.isConfirmModalActive = false
      if (!deleteConfirmed) return
      this.$showSpinner()
      let tmpCompanySetting = _cloneDeep(this.companySetting)
      tmpCompanySetting.emailSignatureImage = null
      this.saveSnapshot({ entity: _cloneDeep(tmpCompanySetting) })
      await this.updateEmailSetting(tmpCompanySetting)
      this.$hideSpinner()
    },
    disableAutoUpdate() {
      if (typeof this.autoUpdate === 'function'
        && typeof this.autoUpdate.cancel === 'function') {
        this.autoUpdate.cancel()
      }
      this.autoUpdate = () => {}
    },
    dropzoneMounted() {
      this.$refs.emailSignatureDropzone.getAcceptedFiles()
    },
    async dropzoneSuccess(file, response) {
      this.$notification.success('Company Signature Image', response)
      this.companySetting = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getEntity' }))
    },
    dropzoneError(file, message, xhr){
      if (message) {
        this.$notification.error('Company Signature Image', message)
      }
    },
    initializeAutoUpdate() {
      this.autoUpdate = _debounce(async (value) => {
        this.snapshotDiff && await this.updateEmailSetting(value)
      }, this.debounceDelay)
    },
    removeImage() {
      this.isConfirmModalActive = true
    },
    async updateEmailSetting(companySetting) {
      if (!this.snapshotDiff) return
      this.$v.$touch()
      if (this.$v.$error) return

      companySetting.deepDiff = JSON.stringify(this.snapshotDiff)
      this.companySetting = _cloneDeep(await this.editStoreItem({
        serviceFunction: 'updateCompanySetting', entity: companySetting }))

      if (this.toastNotificationEnabled) {
        this.openToastNotification()
      }
    }
  },
  validations: {
    companySetting: {
      eSetDisplayName: { required },
      eSetEmail: {
        required,
        email
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    this.toastNotificationEnabled = true
    await this.updateEmailSetting(this.companySetting)
    this.clearSnapshots()
    this.toastNotificationEnabled = false
    next()
  }
}
</script>

<style lang="scss" scoped>
.emailSignature {
  min-height: 90px;
  min-width: 600px;
  max-height: 150px;
  max-width: 650px;
}

#emailSignatureDropzone {
  background:#f8f9fa;
  border: 2px dashed #3291EF;
}
</style>

<style lang="scss">
.dropzone .dz-preview .dz-error-message {
  top: 150px!important;
}
</style>
